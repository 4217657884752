import React, { Component } from 'react';

import {
  Switch,
  Redirect
} from "react-router-dom";

import { withRouter } from "react-router";

// state 
import { connect } from 'react-redux';

// utils 
import lazy from 'utils/lazy';

// components 
import AuthGuard from 'auth-guard/index';

import Login from 'components/login/Login';

import Register from 'components/login/Register';

import TrainerRegister from 'components/login/TrainerRegister';

import ForgetPassword from 'components/login/ForgetPassword';

import ResetPassword from 'components/login/ResetPassword';


// shared components 
import HFNErrorBoundary from 'shared-components/errorBoundary';

import HFNLoader from 'shared-components/lazyLoading';

import HFNToaster from 'shared-components/toasters';

import HFNConfirmDialog from 'shared-components/confirmDialog';

import HFNGallery from 'shared-components/gallery';

import TokenExpire from 'shared-components/logout/tokenExpire';

//import HFNReactJoyride from 'shared-components/tourGuide/index';

// lazy components 
const LayoutContainer = lazy('layouts/Template');

class App extends Component {

  render() {

    return (
      <HFNErrorBoundary>
        <HFNLoader>
          <div className="hfn">
            <Switch>

              <Login path="/login"></Login>

              <Register path="/register" />

              <TrainerRegister path="/trainer-register" />

              <ForgetPassword path="/forgot-password"></ForgetPassword>

              <ResetPassword path="/reset-password/:id"></ResetPassword>

              <AuthGuard path='/' component={LayoutContainer} />

              <Redirect to="login"></Redirect>

            </Switch>
          </div>

          <div>
            <HFNToaster />
            <HFNConfirmDialog />
            <HFNGallery />
            <TokenExpire />
            {/*<HFNReactJoyride/>*/}
          </div>

        </HFNLoader>
      </HFNErrorBoundary>
    );
  }
}

// export default Login
const mapStateToProps = (state) => ({
  ld: state.loginDetails
});

export default withRouter(connect(mapStateToProps)(App));
