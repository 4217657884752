import React, { useState, useRef, useCallback, useEffect } from "react";

import { connect } from "react-redux";

import { useHistory } from "react-router-dom";

//import { Link } from 'react-router-dom';

// utils

import {
//  isLoginAuth,
  getUserRole,
} from "utils/common";

// import { isEmpty } from 'lodash';

// import { response } from 'utils/response';

import { lStorage } from "utils/storage";

// primereact components
import { Card } from "primereact/card";

//import { Button } from 'primereact/button';

//import { HfnFirebaseAuth, signOut } from '@heartfulnessinstitute/react-hfn-profile';

// state

import { LOGIN } from "store/actions/type/login";

// services
import LoginService from "services/login";

// images
import logo from "assets/images/logo.png";

//import HFNDynamicForm from 'shared-components/hfn-form/index';

// import { validations } from 'utils/validations';

// import { useCookies } from 'react-cookie';

import {
  fetchProfileMe,
  HfnFirebaseAuth,
  signOut,
} from "@heartfulnessinstitute/react-hfn-profile";
import { toaster } from "utils/toaster";
import { useCookies } from "react-cookie";

const Login = (props) => {
  let history = useHistory();

  const ls = new LoginService();

  const [cookies, setCookie] = useCookies(["tourRequired", "tourGuidance"]);

  const [loginCheck, setLoginCheck] = useState(false);
  const firebaseRef = useRef(null);

  const processLogin = useCallback(
    ({ myInfo }) => {
      if (myInfo && firebaseRef.current) {
        firebaseRef.current.authFn.onAuthStateChanged((firebaseUser) => {
          if (firebaseUser)
            firebaseUser.getIdToken().then((srcmToken) => {
              setLoginCheck(true);
              if (myInfo) {
                setLoginCheck(false);
                lStorage.set(
                  "logauthInfo",
                  JSON.stringify({ token: srcmToken })
                );
                    loginFormOnsubmit();
                // history.push('/register')
              } else {
                setLoginCheck(false);
                toaster.error("something went wrong fff");
              }
            });
        });
      }
    },
    [history]
  );

  // Login form submit section starts
  const loginFormOnsubmit = async () => {
    let loginResponse, loginResponseData;
    try {
      let loadDD=await fetchProfileMe();
      localStorage.setItem('loadMeData',JSON.stringify(loadDD))
      // if (isEmpty(err)) {
      loginResponse = await ls.login();
      loginResponseData = loginResponse.data;
      if (!loginResponseData.isError) {
        await lStorage.set("authInfo", loginResponseData.data);
        localStorage.setItem("tourRequired", "");
        localStorage.setItem("tourGuidance", "1");
        if (
          (getUserRole() === "Volunteer" ||
            getUserRole() === "Zone Coordinator" ||
            getUserRole() === "State Coordinator" ||
            getUserRole() === "Trainer" ||
            getUserRole() === "College Staff" ||
            getUserRole() === "Central POC") &&
          !cookies.tourRequired
        ) {
          setCookie("tourRequired", "", { path: "/" });
          setCookie("tourGuidance", "1", { path: "/" });
        }
        if (cookies.tourRequired && cookies.tourGuidance) {
          localStorage.setItem("tourRequired", cookies.tourRequired);
          localStorage.setItem("tourGuidance", cookies.tourGuidance);
        }
        props.dispatch({ type: LOGIN, payload: loginResponseData.data });
        if (loginResponseData.data.user_type === "T") {
          history.push("/myprograms");
        } else {
          loginResponseData.data.user_type !== "CS"
            ? history.push("/dashboard")
            : history.push("/mycollege");
        }
      } else if (loginResponseData.message[0] === "User not available") {
        history.push("/register");
      } else {
        toaster.error(loginResponseData.message[0]);
        lStorage.clear();
        history.push('/login')
      }

      //}
    } catch (err) {
      console.log(err);
    }
  };
  // Login form submit section end

  // const isLogin = () => {
  //   if (isLoginAuth()) {
  //     history.push('/')
  //   } else {
  //     history.push('/login')
  //   }
  // }


  useEffect(() => {
    const logVal = localStorage.getItem("logt");
    if(logVal == 'yes'){
      lStorage.clear()
      signOut()     
      window.location.reload()
    }else{
    const authVal = lStorage.get("logauthInfo");
      const authInfo = authVal ? JSON.parse(authVal) : null;
      if (authInfo?.token) history.push("/register");
      else signOut();
    }
  }, [history]);

  return (
    <div className="login-section">
      <div className="card-wrapper">
        <div className="loging-logo p-mb-4 p-text-center">
          <img src={logo} alt="heartfulness" />
        </div>

        <Card>
          <div className="login">
            <div>
              <h2 className="title p-mb-4"> Login</h2>
              <HfnFirebaseAuth
                ref={firebaseRef}
                titleText=""
                doLogin={processLogin}
              />
              {loginCheck ? (
                <div className="p-text-center p-m-4 p-text-bold">
                  {" "}
                  Fetching info...{" "}
                </div>
              ) : null}
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

// export default Login
const mapStateToProps = (state) => ({
  ld: state.loginDetails,
});

export default connect(mapStateToProps)(Login);
