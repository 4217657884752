import React from 'react';

//utils
import { isEmpty, isString, upperFirst } from 'lodash';

import moment from 'moment';

import { getDateString, getFileTypeIcon, getTotalHours } from "utils/common";

import config from 'assets/config';

const image_folder="program-media"

const programStatuses = [
  {
    value: 1,
    label: "Draft",
  },
  {
    value: 2,
    label: "Pending Trainer Assignment",
  },
  {
    value: 3,
    label: "Scheduled",
  },
  {
    value: 4,
    label: "On going",
  },
  {
    value: 5,
    label: "Cancelled",
  },
  {
    value: 6,
    label: "Completed",
  }
];

const trainerStatusBadge = (rowData, { field }) => {
  let status = rowData[field];
  let slug = '';
  if (!isEmpty(rowData.user)) {
    if (!isEmpty(rowData.user.status.status_name)) status = rowData.user.status.status_name;
    if (!isEmpty(rowData.user.status.status_slug)) slug = "p-badge status-" + rowData.user.status.status_slug;
  }

  return slug ? <div className={slug}>{status}</div> : status;
}

const statusBadge = (rowData, { field }) => {
  let status = rowData[field];
  let slug = '';
  if (!isEmpty(rowData.status)) {
    if (!isEmpty(rowData.status.status_name)) status = rowData.status.status_name;
    if (!isEmpty(rowData.status.status_slug)) slug = "p-badge status-" + rowData.status.status_slug;
  }

  return slug ? <div className={slug}>{status}</div> : status;
}
const trainingStatus = (rowData, { field }) => {
  if (rowData[field] === 0)
    return <div>{(rowData[field] === 0) ? "NA" : "-"}</div>
  else
    return <div>{rowData.training_status.status_name}</div>
}

const crStatusBadge = (rowData, { field }) => {
  if (rowData[field])
    return <div className={"p-badge " + ((rowData[field] === 1) ? "cr-pending" : "cr-processed")}>{(rowData[field] === 1) ? "Pending" : "Processed"}</div>
  else
    return "-";
}

const zoneStatusBadge = (rowData, { field }) => {
  let active = rowData[field];

  if ((active === true) || (active === false)) {
    return (<div className={active ? "p-badge status-active" : "p-badge status-in-active"}> {active ? "Active" : "In Active"} </div>)
  }
  else
    return "-";
}

const programStatusBadge = (rowData, { field }) => {
  const programStatus = programStatuses.find(status => rowData[field] === status.value);
  return programStatus ? programStatus.label : "-";
}

const userStatusBadge = (rowData) => {
  return (!isEmpty(rowData.status) && !isEmpty(rowData.status.status_name)) ? <div className="hfn-datatable-td" title={rowData.status.status_name}>{rowData.status.status_name}</div> : "-";
}

const createdDateBadge = (rowData, { field }) => {
  return (!isEmpty(rowData[field])) ? <div className="hfn-datatable-td" title={getDateString(rowData[field])}>{getDateString(rowData[field])}</div> : "-";
}

const countryBadge = (rowData) => {
  return (!isEmpty(rowData.country) && !isEmpty(rowData.country.country_name)) ? <div className="hfn-datatable-td" title={rowData.country.country_name}>{rowData.country.country_name}</div> : "-";
}
const primaryRole = (rowData) => {
  return (!isEmpty(rowData.role) && !isEmpty(rowData.role.role_name)) ? <div className="hfn-datatable-td" title={rowData.role.role_name}>{rowData.role.role_name}</div> : "-";
}
const zoneBadge = (rowData) => {
  return (!isEmpty(rowData.zone) && !isEmpty(rowData.zone.zone_name)) ? <div className="hfn-datatable-td" title={rowData.zone.zone_name}>{rowData.zone.zone_name}</div> : "-";
}

const disciplineBadge = (rowData) => {
  return (!isEmpty(rowData.disciplines) && !isEmpty(rowData.disciplines.discipline_name)) ? <div className="hfn-datatable-td" title={rowData.disciplines.discipline_name}>{rowData.disciplines.discipline_name}</div> : "-";
}

const levelBadge = (rowData) => {
  return (!isEmpty(rowData.levels) && !isEmpty(rowData.levels.level_name)) ? <div className="hfn-datatable-td" title={rowData.levels.level_name}>{rowData.levels.level_name}</div> : "-";
}

const moduleTypeBadge = (rowData) => {
  return (!isEmpty(rowData.module_types) && !isEmpty(rowData.module_types.module_type_name)) ? <div className="hfn-datatable-td" title={rowData.module_types.module_type_name}>{rowData.module_types.module_type_name}</div> : "-";
}

const programTypeBadge = (rowData) => {
  return (!isEmpty(rowData.program_types) && !isEmpty(rowData.program_types.program_type_name)) ? <div className="hfn-datatable-td" title={rowData.program_types.program_type_name}>{rowData.program_types.program_type_name}</div> : "-";
}

const unitBadge = (rowData) => {
  return (!isEmpty(rowData.units) && !isEmpty(rowData.units.unit_name)) ? <div className="hfn-datatable-td" title={rowData.units.unit_name}>{rowData.units.unit_name}</div> : "-";
}

const stateBadge = (rowData, { field }) => {
  return (!isEmpty(rowData[field])) ? <div className="hfn-datatable-td" title={upperFirst(rowData[field])}>{upperFirst(rowData[field])}</div> : "-";
}

const filetypeBadge = (rowData, { field }) => {
  return (!isEmpty(rowData[field])) ? <div className="hfn-datatable-td">{getFileTypeIcon(rowData)}</div> : "-";
}

const imagetypeBadge = (rowData, { field }) => {
  return <img src={config.mediaURL + image_folder + "/" + rowData[field]} alt="program-media" width="150" height="160" />
}

const moduleBadge = rowData => {
  return (!isEmpty(rowData.modules) && !isEmpty(rowData.modules.module_name)) ? <div className="hfn-datatable-td" title={rowData.modules.module_name}>{rowData.modules.module_name}</div> : "-";
}

const cartBadge = rowData => {
  return (!isEmpty(rowData.carts) && !isEmpty(rowData.carts.cart_name)) ? <div className="hfn-datatable-td" title={rowData.carts.cart_name}>{rowData.carts.cart_name}</div> : "-";
}

const collegeBadge = rowData => {
  return (!isEmpty(rowData.colleges) && !isEmpty(rowData.colleges.label)) ? <div className="hfn-datatable-td" title={rowData.colleges.label}>{rowData.colleges.label}</div> : "-";
}

const assetCategoryBadge = rowData => {
  return (!isEmpty(rowData.cat_names) && !isEmpty(rowData.cat_names.label)) ? <div className="hfn-datatable-td" title={rowData.cat_names.label}>{rowData.cat_names.label}</div> : "-";
}

const assetListingCategoryBadge = rowData => {
  return (!isEmpty(rowData.cat_name) && !isEmpty(rowData.cat_name.label)) ? <div className="hfn-datatable-td" title={rowData.cat_name.label}>{rowData.cat_name.label}</div> : "-";
}

const certificateSentBadge = (rowData, { field }) => {
  if (rowData[field] === 0 || rowData[field] === 1)
    return (rowData[field] === 1) ? "Sent" : "Not yet";
  else
    return "-";
}

const overallCertificateSentBadge = (rowData, { field }) => {
  if (rowData[field] === 0 || rowData[field] === 1)
    return (rowData[field] === 1) ? "Eligible" : "Not Eligible";
  else
    return "-";
}

const sessionDateBadge = (rowData, { field }) => {
  if (rowData[field]) {
    let date = new Date(rowData[field])
    return (!isNaN(date)) ? <div className="hfn-datatable-td" title={moment(date).format('MMM DD, YYYY hh:mm A')}>{moment(date).format('MMM DD, YYYY hh:mm A')}</div> : "-";
  }
  return "-";
}

const lookup = (obj, key) => {
  return key.split('.').reduce((o, k) => o && o[k], obj);
}

const gotoPage = (rowData, field, cb) => {

  let data = lookup(rowData, field), fieldName;

  if ((data || (data === 0)) && isString(data)) {
    fieldName = upperFirst(data);
  } else {
    fieldName = data
  }

  if (fieldName) {
    return (
      <span className="gotopage-link">
        <span title={fieldName} onClick={(ev) => { cb(ev, rowData) }}>{fieldName}</span>
      </span>
    )
  } else {
    return (<span>-</span>)
  }

}

const startDateValidate = rowData => {
  let todayDate = new Date()
  return (todayDate.toISOString() < rowData) ? true : false
}


const multipleUnitBadge = rowData => {
  if (!isEmpty(rowData.unitmodules)) {
    let units = "";
    rowData.unitmodules.forEach(unitmodule => {
      if (unitmodule.units && unitmodule.units.unit_name) {
        units += (units === "") ? unitmodule.units.unit_name : ", " + unitmodule.units.unit_name
      }
    });
    return <div className="hfn-datatable-td" title={units || ""}> {units || "-"} </div>
  }
  else
    return "-";
}

const multipleModuleBadge = rowData => {
  if (!isEmpty(rowData.unitmodules)) {
    let modules = "";
    rowData.unitmodules.forEach(unitmodule => {
      if (unitmodule.modules && unitmodule.modules.module_name) {
        modules += (modules === "") ? unitmodule.modules.module_name : ", " + unitmodule.modules.module_name
      }
    });
    return <div className="hfn-datatable-td" title={modules || ""}> {modules || "-"} </div>
  }
  else
    return "-";
}
const staffStatusBadge = (rowData, { field }) => {
  let status = rowData[field];
  let slug = '';
  if (!isEmpty(rowData.user)) {
    if (!isEmpty(rowData.user.status.status_name)) status = rowData.user.status.status_name;
    if (!isEmpty(rowData.user.status.status_slug)) slug = "p-badge status-" + rowData.user.status.status_slug;
  }

  return slug ? <div className={slug}>{status}</div> : status;
}

const totalHoursBadge = (rowData, { field }) => {
  const totalHours = getTotalHours(rowData[field]);

  return totalHours ? <div className="hfn-datatable-td" title={totalHours}> {totalHours} </div> : "-";
}

const faqVisibilityBadge = rowData => {
  if (!isEmpty(rowData.faq_display_type)) {
    const displayType = rowData.faq_display_type === "GENERAL" ? "General" : "College Staff";
    return displayType ? <div className="hfn-datatable-td" title={displayType}> {displayType} </div> : "-";
  } else {
    return "-";
  }
}
const participantCountBadge = (rowData, { field }) => {
  if (isEmpty(rowData.participant_count) && rowData.event_end_date !== undefined) {
    return (rowData[field] === 0 && (moment(new Date()) > moment(new Date(rowData.event_end_date)))) ? "Upload student details" : rowData[field];
  } else if (isEmpty(rowData.participant_count)) {
    return rowData[field]
  } else {
    return "-";
  }
}

const htmlConvertor = rowData => {
  if (!isEmpty(rowData.answer)) {
    return <div dangerouslySetInnerHTML={{ __html: rowData.answer }}></div>
  } else {
    return "-";
  }
}
const getRandomColor = () => {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 15)];
  }
  return color;
}

const getInitials = (name) => {
  let initials;
  const nameSplit = name.split(" ");
  const nameLength = nameSplit.length;
  if (nameLength > 1) {
    initials =
      nameSplit[0].substring(0, 1);
  } else if (nameLength === 1) {
    initials = nameSplit[0].substring(0, 1);
  } else return;

  return initials.toUpperCase();
};

const createImageFromInitials = (size, name, color) => {
  if (name == null) return;
  name = getInitials(name)

  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  canvas.width = canvas.height = size

  context.fillStyle = "#ffffff"
  context.fillRect(0, 0, size, size)

  context.fillStyle = `${color}50`
  context.fillRect(0, 0, size, size)

  context.fillStyle = color;
  context.textBaseline = 'middle'
  context.textAlign = 'center'
  context.font = `${size / 2}px Pacifico`
  context.fillText(name, (size / 2), (size / 2))

  return canvas.toDataURL()
};

const secondaryRole = (rowData) => {
 if(!isEmpty(rowData.secondary_user_type) && rowData.secondary_user_type === "T") {
   return "Trainer"
 } else if(!isEmpty(rowData.secondary_user_type) && rowData.secondary_user_type === "GT") {
  return "Global Trainer"
} else if(!isEmpty(rowData.secondary_user_type) && rowData.secondary_user_type === "TC") {
  return "Training Coordinator"
}else{
  return "-";
}
};

const promotedRole = (rowData) => {
  if(!isEmpty(rowData.secondary_user_type)) {
    if(rowData.secondary_user_type === "T")
      return "Trainer";
    else if(rowData.secondary_user_type === "GT")
      return "Global Trainer";
    else if(rowData.secondary_user_type === "TC")
      return "Training Coordinator";
    else
      return "-";
  }
}

  const validatePIN =(pin)=> {
    return /^[1-9]{1}[0-9]{2}\s?[0-9]{3}$/.test(pin);
}

export {
  statusBadge,
  createdDateBadge,
  countryBadge,
  disciplineBadge,
  levelBadge,
  moduleTypeBadge,
  programTypeBadge,
  unitBadge,
  stateBadge,
  filetypeBadge,
  userStatusBadge,
  imagetypeBadge,
  moduleBadge,
  cartBadge,
  collegeBadge,
  trainerStatusBadge,
  assetCategoryBadge,
  assetListingCategoryBadge,
  crStatusBadge,
  programStatusBadge,
  certificateSentBadge,
  overallCertificateSentBadge,
  sessionDateBadge,
  gotoPage,
  zoneStatusBadge,
  multipleUnitBadge,
  multipleModuleBadge,
  staffStatusBadge,
  startDateValidate,
  totalHoursBadge,
  faqVisibilityBadge,
  participantCountBadge,
  htmlConvertor,
  getRandomColor,
  createImageFromInitials,
  getInitials,
  secondaryRole,
  trainingStatus,
  promotedRole,
  zoneBadge,
  primaryRole,
  validatePIN
}

