import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";

// components
// primereact components
import { Card } from "primereact/card";

import { Button } from "primereact/button";

import { Divider } from "primereact/divider";

// shared components
import HFNDynamicForm from "shared-components/hfn-form/index";

// utils
import { isEmpty } from "lodash";

import { validations } from "utils/validations";

import { dropdown } from "utils/dropdown";

import { response } from "utils/response";

import { cityAutoCompleteTemplate, isLoginAuth } from "utils/common";

// services
import LoginService from "services/login";

// assets
import logo from "assets/images/logo.png";

import config from "assets/config";

const TrainerRegister = () => {
  let history = useHistory();

  const loginService = new LoginService();

  const passwordHeader = <h6>Pick a password</h6>;

  const passwordFooter = (
    <>
      <Divider />
      <p className="p-mt-2">Suggestions</p>
      <ul className="p-pl-2 p-ml-2 p-mt-0" style={{ lineHeight: "1.5" }}>
        <li>At least one lowercase</li>
        <li>At least one uppercase</li>
        <li>At least one numeric</li>
        <li>At least one special character</li>
        <li>Minimum 8 characters</li>
      </ul>
    </>
  );

  // state management start
  const [formConfig] = useState({
    formClassName: "register-form-wrapper",
    formSectionClassName: "register-form-section",
    autoComplete: "off"
  });

  const [formFields, setFormFields] = useState({
    name: {
      properties: {
        type: "InputText",
        label: "Name",
        fieldWrapperClassNames: "p-md-6",
        primeFieldProps: {},
        validations: {
          required: validations.required
        }
      }
    },

    email_address: {
      properties: {
        type: "InputText",
        label: "Email",
        fieldWrapperClassNames: "p-md-6",
        primeFieldProps: {
          keyfilter: "email"
        },
        validations: {
          required: validations.required,
          pattern: validations.email
        }
      }
    },

    password: {
      properties: {
        type: "Password",
        label: "Password",
        fieldWrapperClassNames: "p-md-6",
        primeFieldProps: {
          header: passwordHeader,
          footer: passwordFooter,
          autoComplete: "off"
        },
        validations: {
          required: validations.required
        }
      }
    },

    contact_number: {
      properties: {
        type: "PhoneInput",
        label: "Contact Number",
        fieldWrapperClassNames: "p-md-6",
        primeFieldProps: {},
        validations: {
          required: validations.required
        }
      }
    },


    photo: {
      properties: {
        type: 'ProfileUpload',
        label: 'Photo',
        fieldWrapperClassNames: 'p-md-12',
        hint: `Maximum allowed file size is ${config.profileImageMaxFIleSize}MB. Allowed types are jpg, jpeg and png`,
        primeFieldProps: {
          accept: ".jpg,.jpeg,.png",
          onChange: (file) => {
            if (file.target.files.length !== 0) {
              setFormFields({
                ...formFields,
                imageCrop: {
                  ...formFields.imageCrop,
                  properties: {
                    ...formFields.imageCrop.properties,
                    visibility: true
                  }
                },
                previewImage: {
                  ...formFields.previewImage,
                  properties: {
                    ...formFields.previewImage.properties,
                    visibility: true
                  }
                }
              })
            } else {
              setFormFields({
                ...formFields,
                imageCrop: {
                  ...formFields.imageCrop,
                  properties: {
                    ...formFields.imageCrop.properties,
                    visibility: false
                  }
                },
                previewImage: {
                  ...formFields.previewImage,
                  properties: {
                    ...formFields.previewImage.properties,
                    visibility: false
                  }
                }
              })
            }
          }
        }
      }
    },

    imageCrop: {
      properties: {
        type: 'imageCrop',
        label: 'Selected Image',
        visibility: false,
        fieldWrapperClassNames: 'p-md-6',
        primeFieldProps: {
          accept: ".jpg, .jpeg, .png"
        },
      }
    },
    previewImage: {
      properties: {
        type: 'previewImage',
        label: 'Preview Image',
        visibility: false,
        fieldWrapperClassNames: 'p-md-6',
        primeFieldProps: {
          accept: ".jpg, .jpeg, .png"
        },
      }
    },

    address: {
      properties: {
        type: "InputTextarea",
        label: "Address",
        fieldWrapperClassNames: "p-col-12",
        primeFieldProps: {}
      }
    },

    city: {
      properties: {
        type: "CityAutoComplete",
        label: "City",
        fieldWrapperClassNames: "p-md-6",
        searchField: "name",
        fieldLabel: "name",
        primeFieldProps: {
          itemTemplate: cityAutoCompleteTemplate
        },
        validations: {
          required: validations.required,
          minLength: {
            value: 3,
            message: "Search value must be minimum 3 character..."
          }
        },
        stateField: {
          label: "State",
          fieldName: "state",
          fieldWrapperClassNames: "p-md-6",
          primeFieldProps: {
            readOnly: true
          },
          validations: {
            required: validations.required
          }
        },
        countryField: {
          label: "Country",
          fieldName: "country_id",
          fieldWrapperClassNames: "p-md-6",
          primeFieldProps: {
            disabled: true
          },
          validations: {
            required: validations.required
          },
          dropdownOptions: "country"
        }
      }
    },
    pincode: {
      properties: {
        type: "InputText",
        label: "Pincode",
        fieldWrapperClassNames: "p-md-6",
        primeFieldProps: {}
      }
    },

    zone_id: {
      properties: {
        type: "SelectDropdown",
        label: "Zone",
        fieldWrapperClassNames: "p-md-6",
        primeFieldProps: {
          isSearchable: true
        },
        validations: {
          required: validations.required
        },
        dropdownOptions: "zone"
      }
    }
  });
  // state management end

  useEffect(() => {
    if (isLoginAuth()) {
      history.push("/");
    }
    dropdown.zone();
    dropdown.country();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // form button group section start
  const formButtonGroup = () => {
    return (
      <div className="p-d-flex p-jc-end p-mt-3 p-pr-2">
        <Button type="button" className="p-button-secondary p-mr-2" label="Cancel" onClick={() => { history.push("/login"); }} />
        <Button type="submit" label="Submit" className="p-button-success" />
      </div>
    )
  };
  // form button group section end

  // form submit section start
  const formOnSubmit = async (data, error) => {
    if (isEmpty(error)) {
      try {
        let formData = new FormData();

        Object.keys(data).forEach(key => {
          if (key === "photo") {
            if (data[key][0])
              formData.append(key, data[key]);
          }
          else
            formData.append(key, data[key]);
        });
        formData.append("user_type", "T");
        formData.append("role_id", 6);

        let apiResponse = await response.add({
          service: loginService,
          method: "register",
          data: { item: formData },
          toasterMessage: {
            success: "Please check your email for the account activation link",
            error: "Trainer not registered"
          }
        });

        if (apiResponse && apiResponse.data && !apiResponse.data.isError) {
          setTimeout(() => {
            history.push("/login");
          }, 2000);
        }
      }
      catch {
        console.log("Something went wrong.");
      }
    }
  };
  // form submit section end

  return (
    <div className="login-section register-section">
      <div className="card-wrapper">

        <div className="loging-logo p-mb-4 p-text-center">
          <img src={logo} alt="heartfulness" />
        </div>

        <Card>
          <div className="register">
            <h2 className="title p-mb-4">Trainer Register</h2>
            <HFNDynamicForm
              formConfig={formConfig}
              initialValues={{}}
              fields={formFields}
              onFormSubmit={formOnSubmit}
              submitButtonGroup={formButtonGroup}
            />
          </div>
        </Card>

      </div>
    </div>
  )
}

export default TrainerRegister;
