// eslint-disable-next-line no-undef
const configData = process.env;
let config = {};

try {
  config.apiURL = configData.REACT_APP_API_BASE_URI || "https://writenodeedu.heartfulness.org/hceportal-dev-api/api/v1/";
  config.mediaURL = configData.REACT_APP_MEDIA_URL || "https://heartfulcampuseportal.s3.ap-south-1.amazonaws.com/";
  config.cityAutoCompleteURL = configData.REACT_APP_AUTOCOMPLETE_API || "https://us-central1-hfn-registration-qa.cloudfunctions.net/";
  config.srcmStaticDataURL = configData.REACT_APP_STATIC_DATA_SRCM_URL || "https://static-gatsby-qa.web.app/srcmapi/";
}
catch {
  config.apiURL = "https://writenodeedu.heartfulness.org/hceportal-dev-api/api/v1/";
  config.mediaURL = "https://heartfulcampuseportal.s3.ap-south-1.amazonaws.com/";
  config.cityAutoCompleteURL = "https://us-central1-hfn-registration-qa.cloudfunctions.net/";
  config.srcmStaticDataURL = "https://static-gatsby-qa.web.app/srcmapi/";
}

config.localApiURL = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;

config.srcmURL = "https://profile.srcm.net/api/v2/";

config.videoUrlEmbed = "https://www.youtube.com/embed/";

config.maxAllowedFileSize = 20;
config.profileImageMaxFIleSize = 1;

config.maxPageSize = 20;

config.programStatusOptions = [
  {
    value: 1,
    label: "Draft"
  },
  {
    value: 2,
    label: "Pending Trainer Assignment"
  },
  {
    value: 3,
    label: "Scheduled"
  },
  {
    value: 4,
    label: "On going"
  },
  {
    value: 5,
    label: "Cancelled"
  },
  {
    value: 6,
    label: "Completed"
  }
];

config.programStatusDropdown = [
  {
    value: 1,
    label: "Pending"
  },
  {
    value: 2,
    label: "Processed"
  },
];

config.trainerSearchOption = [
  {
    value: "T",
    label: "Trainer"
  },
  {
    value: "TC",
    label: "Training Coordinator"
  }
];

config.fontFamilyValues = [
  { label: 'Arial', value: 'Arial' },
  { label: 'Courier', value: 'Courier' },
  { label: 'Times', value: 'Times' },
  { label: 'Helvetica', value: 'Helvetica' },
  { label: 'Goudy Old Style', value: 'Goudy Old Style' }
];

config.participantConfigDropdown = [
  {
    value: 'session',
    label: "Session Count"
  },
  {
    value: 'percentage',
    label: "Participation Percentage"
  },
];

config.artifactTypeDropdown = [
  {
    value: 'mou',
    label: "MOU"
  },
  {
    value: 'media',
    label: "Media"
  }
];

config.userTypes = [
  { label: "Volunteer", value: "U" },
  { label: "POC", value: "POC" },
  { label: "College Staff", value: "CS" },
  { label: "Trainer", value: "T" }
];
config.durationTimingValues = [
  { label: '01:00 Hrs', value: '1:00' },
  { label: '01:30 Hrs', value: '1:30' },
  { label: '02:00 Hrs', value: '2:00' },
  { label: '02:30 Hrs', value: '2:30' },
  { label: '03:00 Hrs', value: '3:00' },
  { label: '03:30 Hrs', value: '3:30' },
  { label: '04:00 Hrs', value: '4:00' },
  { label: '04:30 Hrs', value: '4:30' },
  { label: '05:00 Hrs', value: '5:00' },
  { label: '05:30 Hrs', value: '5:30' },
  { label: '06:00 Hrs', value: '6:00' }
];

config.promoteStatusOptions = [
  // {
  //   value: "GT",
  //   label: "Global Trainer"
  // },
  {
    value: "T",
    label: "Trainer"
  },
  {
    value: "TC",
    label: "Training Coordinator"
  }
];

export default config;
