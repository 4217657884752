const userModuleRoutes = [
  "/",
  "dashboard",
  "assets",
  "assets/category",
  "assets/listing",
  "account",
  "college",
  "college/listing",
  // "college/reports",
  "college/details/:id",
  "college/cart/checkout/:id",
  "college/cart/:id",
  "college/:collegeID/program/:programID/session/:sessionID/participant",
  "college/:collegeID/program/:programID/session",
  "user",
  "user/listing",
  // "user/report",
  // "user/collegestaff",
  "program",
  "program/listing",
  // "program/changerequest",
  // "program/reports",
  "program/:programID/session",
  "program/:programID/session/:sessionID/participant",
  "trainer",
  "trainerbatch",
  "trainerbatch/batchlisting",
  "trainerbatch/:id/batchDetails",
  "trainer/listing",
  // "trainer/report",
  "trainer/:trainerID/programs/:sessionID/participant",
  "trainer/:id/programs",
  "module",
  "cms",
  "faq",
  "frequently-asked-questions",
  "help",
  "help/details/:id",
  "standard-data",
  "standard-data/zone",
  "standard-data/country",
  "standard-data/level",
  "standard-data/discipline",
  "standard-data/unit",
  "standard-data/moduletype",
  "standard-data/programtype",
  "standard-data/agegroup",
  "standard-data/language",
  "standard-data/faqcategory",
  "standard-data/batch",
  "myprograms"
];

const trainerModuleRoutes = [
  "/",
  "dashboard",
  "account",
  "frequently-asked-questions",
  "help",
  "help/details/:id",
  "trainer-listing",
  "myprograms",
  "myprograms/:sessionID/participant",
  "notify-unavailability",
  "trainerbatch",
  "trainerbatch/batchlisting",
  "trainerbatch/:id/batchDetails"
]

const userPageAccess = [
  {
    userType: "U",
    route: [
      {
        userRole: "Superadmin",
        excludeRoutes: [
        ],
        includeRoutes: [
          "configurations",
          "privilege",
        ]
      },
      {
        userRole: "Admin",
        excludeRoutes: [

        ],
        includeRoutes: [
          "configurations"
        ]
      },
      {
        userRole: "Central POC",
        excludeRoutes: [],
        includeRoutes: []
      },
      {
        userRole: "State Coordinator",
        excludeRoutes: [],
        includeRoutes: []
      },
      {
        userRole: "State Training Coordinator",
        excludeRoutes: [],
        includeRoutes: []
      },
      {
        userRole: "Zone Coordinator",
        excludeRoutes: [],
        includeRoutes: []
      },
      {
        userRole: "Volunteer",
        excludeRoutes: [],
        includeRoutes: []
      },
      {
        userRole: "Content Editor",
        excludeRoutes: [],
        includeRoutes: []
      },
      {
        userRole: "Normal User",
        excludeRoutes: [],
        includeRoutes: []
      },
      {
        userRole: "Training Coordinator",
        excludeRoutes: [

        ],
        includeRoutes: [

        ]
      }
    ]
  },
  {
    userType: "CS",
    route: [
      "/",
      "account",
      "mycollege",
      "frequently-asked-questions",
      "help",
      "help/details/:id",
      "mycollege/cart/checkout",
      "mycollege/cart",
      "mycollege/program/:programID/session",
      "mycollege/program/:programID/session/:sessionID/participant",
    ]
  },
  {
    userType: "T",
    route: [
      {
        userRole: "Admin",
        excludeRoutes: [

        ],
        includeRoutes: [
          "trainer-listing",
          "trainerbatch",
          "trainerbatch/batchlisting",
          "trainerbatch/:id/batchDetails" 
        ]
      },
      {
        userRole: "Central POC",
        excludeRoutes: [
          "trainer-listing",
          "trainerbatch",
          "trainerbatch/batchlisting",
          "trainerbatch/:id/batchDetails" 
        ],
        includeRoutes: []
      },
      {
        userRole: "State Coordinator",
        excludeRoutes: [
          "trainer-listing",
          "trainerbatch",
          "trainerbatch/batchlisting",
          "trainerbatch/:id/batchDetails" 
        ],
        includeRoutes: []
      },
      {
        userRole: "State Training Coordinator",
        excludeRoutes: [
          "trainer-listing",
          "trainerbatch",
          "trainerbatch/batchlisting",
          "trainerbatch/:id/batchDetails" 
        ],
        includeRoutes: []
      },
      {
        userRole: "Zone Coordinator",
        excludeRoutes: [
          "trainer-listing",
          "trainerbatch",
          "trainerbatch/batchlisting",
          "trainerbatch/:id/batchDetails" 
        ],
        includeRoutes: []
      },
      {
        userRole: "Volunteer",
        excludeRoutes: [
          "trainer-listing",
          "trainerbatch",
          "trainerbatch/batchlisting",
          "trainerbatch/:id/batchDetails" 
        ],
        includeRoutes: []
      },
      {
        userRole: "Content Editor",
        excludeRoutes: [
          "trainer-listing",
          "trainerbatch",
          "trainerbatch/batchlisting",
          "trainerbatch/:id/batchDetails" 
        ],
        includeRoutes: []
      },
      {
        userRole: "Normal User" ,
        excludeRoutes: [
          "trainer-listing",
          "trainerbatch",
          "trainerbatch/batchlisting",
          "trainerbatch/:id/batchDetails" 
        ],
        includeRoutes: [
        ]
      },
      {
        userRole: "Training Coordinator",
        excludeRoutes: [

        ],
        includeRoutes: [

        ]
      }
    ]
  },
  {
    userType: "POC",
    route: [
      "/",
      "dashboard",
      "account",
      "frequently-asked-questions",
      "help",
      "help/details/:id",
      "college",
      "college/listing",
      "college/details/:id",
      "college/cart/checkout/:id",
      "college/cart/:id",
      "college/:collegeID/program/:programID/session/:sessionID/participant",
      "college/:collegeID/program/:programID/session",
      "program",
      "program/listing",
      "program/:programID/session",
      "program/:programID/session/:sessionID/participant",
      // "program/changerequest"
    ]
  }
];

export {
  userPageAccess,
  userModuleRoutes,
  trainerModuleRoutes
};
