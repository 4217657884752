export const STATUS = 'SET_STATUSES';
export const COUNTRY = 'SET_COUNTRIES';
export const ZONE = 'SET_ZONES';
export const UNIT = 'SET_UNITS';
export const DISCIPLINE = 'SET_DISCIPLINES';
export const LEVEL = 'SET_LEVELS';
export const MODULETYPE = 'SET_MODULE_TYPES';
export const PROGRAMTYPE = 'SET_PROGRAM_TYPES';
export const LANGUAGE = 'SET_LANGUAGES';
export const ASSETCATEGORRY = 'SET_ASSET_CATEGORIES';
export const AGEGROUP = 'SET_AGE_GROUPS';
export const GENERALSTATUS = 'SET_GENERAL_STATUSES';
export const USERSTATUS = 'SET_USER_STATUSES';
export const COLLEGESTATUS = 'SET_COLLEGE_STATUSES';
export const TRAINERSTATUS = 'SET_TRAINER_STATUSES';
export const PROGRAMSTATUS = 'SET_PROGRAM_STATUSES';
export const CLEAR = 'CLEAR_ALL_DROPDOWNS';
export const USERROLE = 'USER_ROLE';
export const TRAINER = 'SET_TRAINERS';
export const COLLEGEPOC = 'SET_AVAILABLE_VOLUNTEERS_FOR_COLLEGE_POC';
export const MODULELIST = 'SET_MODULELIST';
export const USERROLELIST = 'SET_USERROLELIST';
export const SESSIONMODULE = 'SET_SESSION_CREATE_MODULES';
export const PROGRAMREPORTSTATUS = 'SET_PROGRAM_REPORT_STATUS';
export const FONTFAMILY = 'SET_FONT_FAMILY';
export const FAQCATEGORY = 'SET_FAQ_CATEGORY';
export const BATCH = "SET_BATCHES";
export const DURATIONTIMING = "SET_DURATION_TIMING";
export const CERTIFICATELEVEL = "SET_CERTIFICATE_LEVEL";
export const TRAINERFILTER = "SET_TRAINERFILTER";
export const PARTICIPANTCONFIGTYPE = 'SET_PARTICIPANT_CONFIG_TYPE'; 
export const ARTIFACTTYPE = 'SET_ARTIFACT_TYPE';
export const COLLEGES = 'SET_COLLEGES';
export const PROMOTEOPTIONS = 'SET_PROMOTE_OPTIONS';
export const TRAINERBATCHSTATUS = 'SET_TRAINER_BATCH_STATUS';
export const TRAININGPARTICIPANTSTATUS = 'SET_TRAINING_PARTICIPANT_STATUS';
