import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";

// service
import LoginService from "services/login";

// utitls
import { validations } from "utils/validations";

import { dropdown } from "utils/dropdown";

import { response } from "utils/response";

import {  getUserRole } from "utils/common";

// primereact components
import { Card } from "primereact/card";

import { Button } from "primereact/button";

import { isEmpty } from "lodash";

// shared component
import HFNDynamicForm from "shared-components/hfn-form/index";

// images
import logo from "assets/images/logo.png";

import config from "assets/config";
import { lStorage } from "utils/storage";
import { useCookies } from "react-cookie";
import { LOGIN } from "store/actions/type/login";
import { connect } from "react-redux";
import { toaster } from "utils/toaster";
import { validatePIN } from "utils/badgeTemplate";

const Register = (props) => {
  let history = useHistory();

  const ls = new LoginService();
  const [cookies, setCookie] = useCookies(["tourRequired", "tourGuidance"]);

  // register form section start
  const logData =
    localStorage && localStorage.getItem("loadMeData")
      ? localStorage.getItem("loadMeData")
      : null;
  const registerData = logData && logData !== null ? JSON.parse(logData) : null;

  const [registerFormConfig] = useState({
    formClassName: "register-form-wrapper",
    formSectionClassName: "register-form-section",
    autoComplete: "new-password",
  });

  // initial values
  const [registerFormInitialValue] = useState({
    abhyasi_id: registerData && registerData.ref ? registerData.ref : null,
    name: registerData && registerData.name ? registerData.name : null,
    email_address:
      registerData && registerData.email ? registerData.email : null,
    contact_number:
      registerData && registerData.phone ? registerData.phone : null,
    address: null,
    state: null,
    city: null,
    country_id: null,
    pincode: null,
    photo: null,
    zone_id: '',
    role_id: "4",
    status_id: null,
  });

  const [registerFormFields, setRegisterFormFields] = useState({
    name: {
      properties: {
        type: "InputText",
        label: "Name",
        fieldWrapperClassNames: "p-md-6",
        primeFieldProps: {},
        validations: {
          required: validations.required,
        },
      },
    },

    email_address: {
      properties: {
        type: "InputText",
        label: "Email",
        fieldWrapperClassNames: "p-md-6",
        primeFieldProps: {
          keyfilter: "email",
          readOnly: true
        },
        validations: {
          required: validations.required,
          pattern: validations.email,
        },
      },
    },

    abhyasi_id: {
      properties: {
        type: "InputText",
        label: "Abhyasi ID",
        fieldWrapperClassNames: "p-md-6",
        validations: {
          pattern: validations.abhyasiId,
        },
      },
    },

    contact_number: {
      properties: {
        type: "PhoneInput",
        label: "Contact Number",
        fieldWrapperClassNames: "p-md-6",
        primeFieldProps: {},
        validations: {
          required: validations.required,
        },
      },
    },
    country: {
      properties: {
        type: "DropdownCountry",
      label: 'Country',
      fieldName: 'country_id',
      fieldWrapperClassNames: 'p-md-6',
      primeFieldProps: {
      },
      validations: {
        required: validations.required
      },
      dropdownOptions: "country"
    }
    },
    state: {
      properties: {
        type: "StateAutoCom",
      label: 'State',
      fieldName: 'state',
      fieldWrapperClassNames: 'p-md-6',
      primeFieldProps: {
      },
      validations: {
        required: validations.required
      },
    }
    },
    city: {
      properties: {
        type: "CityAutoCompleteRegister",
        label: "City",
        fieldWrapperClassNames: "p-md-6",
        searchField: "name",
        fieldLabel: "name",
        validations: {
          required: validations.required,
          minLength: {
            value: 3,
            message: "Search value must be minimum 3 character...",
          },
        }
      },
    },
    pincode: {
      properties: {
        type: "InputText",
        label: "Pincode",
        fieldWrapperClassNames: "p-md-6",
        primeFieldProps: {},
        validations: {
          required: validations.required,
          maxLength: {
            value: 10,
            message: "Please enter pincode with maximum 10 characters",
          },
        },
      },
    },
    address: {
      properties: {
        type: "InputTextarea",
        label: "Address",
        fieldWrapperClassNames: "p-col-12",
        primeFieldProps: {},
        // validations: {
        //   required: validations.required,
        // }
      },
    },
    photo: {
      properties: {
        type: "ProfileUpload",
        label: "Photo",
        fieldWrapperClassNames: "p-md-6",
        hint: `Maximum allowed file size is ${config.profileImageMaxFIleSize}MB. Allowed types are jpg, jpeg and png`,
        primeFieldProps: {
          accept: ".jpg,.jpeg,.png",
          onChange: (file) => {
            if (file.target.files.length !== 0) {
              setRegisterFormFields({
                ...registerFormFields,
                imageCrop: {
                  ...registerFormFields.imageCrop,
                  properties: {
                    ...registerFormFields.imageCrop.properties,
                    visibility: true,
                  },
                },
                previewImage: {
                  ...registerFormFields.previewImage,
                  properties: {
                    ...registerFormFields.previewImage.properties,
                    visibility: true,
                  },
                },
              });
            } else {
              setRegisterFormFields({
                ...registerFormFields,
                imageCrop: {
                  ...registerFormFields.imageCrop,
                  properties: {
                    ...registerFormFields.imageCrop.properties,
                    visibility: false,
                  },
                },
                previewImage: {
                  ...registerFormFields.previewImage,
                  properties: {
                    ...registerFormFields.previewImage.properties,
                    visibility: false,
                  },
                },
              });
            }
          },
        },
      },
    },

    imageCrop: {
      properties: {
        type: "imageCrop",
        label: "Selected Image",
        visibility: false,
        fieldWrapperClassNames: "p-md-6",
        primeFieldProps: {
          accept: ".jpg, .jpeg, .png",
        },
      },
    },
    previewImage: {
      properties: {
        type: "previewImage",
        label: "Preview Image",
        visibility: false,
        fieldWrapperClassNames: "p-md-6",
        primeFieldProps: {
          accept: ".jpg, .jpeg, .png",
        },
      },
    },
  });

  // const loginFormOnsubmit = async () => {

  // };

  // Registration form submit section starts
  const registerFormOnsubmit = async (data, err) => {
    let registerResponse;
    const findCountry=data.contact_number.startsWith("+91",)
    const pinValid = findCountry == true ? validatePIN(data.pincode) :'notIndia';
    if((findCountry === true && pinValid === true) || pinValid === 'notIndia'){
    let formData = new FormData();
    formData.append('zone_id', '')
    Object.keys(data).forEach((key) => {
      if (key === "photo") {
        if (data[key][0]) {
          formData.append("photo", data[key]);
        }
      } else if (key === "country") {
        formData.append('country_id', data.country[0].value);
      }
      else if (key === "state") {
        formData.append(key, data.state[0].label);
      }else if (key === "city") {
        formData.append(key, data.city[0].label);
      }
      else if (key === "role_id" && data[key] === 10) {
        formData.append(key, data[key]);
        formData.append("user_type", "T");
        formData.append("secondary_user_type", "TC");
      } else formData.append(key, data[key]);      
    });
    try {
      if (isEmpty(err)) {
        registerResponse = await response.add({
          service: ls,
          method: "register",
          data: { item: formData },
          toasterMessage: {
            success: "Registered successfully",
            error: "User not registered",
          },
        });
        if (!registerResponse.data.isError) {
          let loginResponse, loginResponseData;
          try {
            // if (isEmpty(err)) {
            loginResponse = await ls.login();
            loginResponseData = await loginResponse.data;
            if (!loginResponseData.isError) {
              await lStorage.set("authInfo", loginResponseData.data);
              localStorage.setItem("tourRequired", "");
              localStorage.setItem("tourGuidance", "1");
              if (
                (getUserRole() === "Volunteer" ||
                  getUserRole() === "Zone Coordinator" ||
                  getUserRole() === "State Coordinator" ||
                  getUserRole() === "Trainer" ||
                  getUserRole() === "College Staff" ||
                  getUserRole() === "Central POC") &&
                !cookies.tourRequired
              ) {
                setCookie("tourRequired", "", { path: "/" });
                setCookie("tourGuidance", "1", { path: "/" });
              }
              if (cookies.tourRequired && cookies.tourGuidance) {
                localStorage.setItem("tourRequired", cookies.tourRequired);
                localStorage.setItem("tourGuidance", cookies.tourGuidance);
              }
              props.dispatch({ type: LOGIN, payload: loginResponseData.data });
              if (loginResponseData.data.user_type === "T") {
                history.push("/myprograms");
              } else {
                loginResponseData.data.user_type !== "CS"
                  ? history.push("/dashboard")
                  : history.push("/mycollege");
              }
            }           
            else {
                toaster.error(loginResponseData.message)
            }

            //}
          } catch (err) {
            console.log(err);
          }
        }

        // if (!registerResponseData.isError) {
        //   setTimeout(() => {
        //     history.push("/login");
        //   }, 2000);
        // }
      }
    } catch (err) {
      console.log(err);
    }
  }else{
    toaster.error('Please enter valid pincode')
  }
  };
  // Registration form submit section end

  const registerSubmitButtonGroup = () => {
    return (
      <div className="p-d-flex p-jc-end p-mt-3 p-pr-2">
        <Button
          type="button"
          className="p-button-primary p-mr-2"
          label="Logout"
          onClick={() => {
            LogOutSignup();
          }}
        ></Button>
        <Button
          type="button"
          className="p-button-secondary p-mr-2"
          label="Cancel"
          onClick={() => {
            history.push("/login");
          }}
        ></Button>
        <Button type="submit" label="Submit" className="p-button-success" />
      </div>
    );
  };

  // const isLogin = () => {
  //   if (isLoginAuth()) {
  //     history.push('/')
  //   }
  // }

  const LogOutSignup = () => {
    localStorage.setItem("logt", "yes");
    history.push("/login");
  };

  useEffect(async () => {
    dropdown.zone();
    dropdown.country();
    dropdown.userRoleList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="login-section register-section">
      <div className="card-wrapper">
        <div className="loging-logo p-mb-4 p-text-center">
          <img src={logo} alt="heartfulness" />
        </div>

        <Card>
          <div className="register">
            <h2 className="title p-mb-4">Register</h2>
            <HFNDynamicForm
              formConfig={registerFormConfig}
              initialValues={registerFormInitialValue}
              fields={registerFormFields}
              onFormSubmit={registerFormOnsubmit}
              submitButtonGroup={registerSubmitButtonGroup}
            ></HFNDynamicForm>
          </div>
        </Card>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  ld: state.loginDetails,
});

export default connect(mapStateToProps)(Register);
